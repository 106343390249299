<template>
  <div class="login">
    <div class="container-xl pt-5 pb-5">
      <div class="card m-auto p-3">
      <div class="card-body">
        <form @submit.prevent.stop="submit">
          <div class="form-group">
            <label class="text-muted" for="username">Электронная почта (логин)</label>
            <input v-model="username" class="form-control form-control-lg" id="username" type="text" required>
          </div>
          <div class="form-group">
            <label class="text-muted" for="password">Пароль (не менее 8 символов)</label>
            <div class="input-group input-group-lg">
              <input v-model="password" :type="passwordVisible ? 'text' : 'password'" class="form-control" id="password" required>
              <div class="input-group-append">
                <a class="btn btn-light" @click="togglePassword()">
                  <i :class="{ 'bi-eye-slash': passwordVisible, 'bi-eye': !passwordVisible}"></i>
                </a>
              </div>
            </div>
          </div>
          <input-errors :errors="errors.credentials"/>
          <div class="form-group text-right">
            <public-link to="/auth/forgot-password">
              Забыли пароль?
            </public-link>
          </div>
          <div class="form-group">
            <button class="btn btn-lg btn-success w-100" type="submit">Войти</button>
          </div>
      </form>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InputErrors, PublicLink } from "@/components/common";
import { Forbidden, Unauthorized } from "@/utils/api-client";
import { createAuthManager, createAuthTokenManager } from "@/utils/di";

export default {
  name: "Login",
  components: {
    InputErrors,
    PublicLink
  },
  data() {
    return {
      username: "",
      password: "",
      passwordVisible: false,
      errors: {}
    };
  },
  methods: {
    async submit() {
      try {
        this.errors = {};
        const manager = createAuthManager();
        const bearerToken = await manager.login(this.username, this.password);
        createAuthTokenManager().create(bearerToken);
        const redirectTo = sessionStorage.getItem("redirectTo");
        !redirectTo && sessionStorage.setItem("loggedIn", "login");
        return this.$router.replace(redirectTo || { name: "dashboard" });
      } catch (e) {
        if (e instanceof Unauthorized) {
          this.errors = {
            credentials: ["Неверный логин или пароль"]
          };
          this.$notifications.addAlert("Неверный логин или пароль");
        } else if (e instanceof Forbidden) {
          this.errors = {
            credentials: ["Почта не подтверждена. " +
            "Для подтверждения перейдите по ссылке в письме, отправленному на ваш почтовый адрес."]
          };
          this.$notifications.addAlert("Почта не подтверждена");
        } else {
          console.error(e);
        }
      }
    },
    togglePassword() {
      this.passwordVisible = !this.passwordVisible;
    }
  }
};
</script>

<style lang="scss">
.login {
  .card {
    max-width: 35rem;

    .input-group-append {
      .btn-light,
      .btn-light:hover,
      .btn-light:focus,
      .btn-light:active {
        background: #fff;
        border-color: #ced4da;
        border-left: none;
      }
    }

    .input-errors {
      .small {
        font-size: 1rem;
      }
    }
  }
}
</style>
